import { render, staticRenderFns } from "./PcGameItem.vue?vue&type=template&id=557210df&scoped=true"
import script from "./PcGameItem.vue?vue&type=script&lang=js"
export * from "./PcGameItem.vue?vue&type=script&lang=js"
import style0 from "./PcGameItem.vue?vue&type=style&index=0&id=557210df&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "557210df",
  null
  
)

export default component.exports